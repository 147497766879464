



















import Vue from 'vue'

export default Vue.extend({
  name: 'Portfolio',
  props: {
    gigs: {
      type: Array,
      required: true,
      default: () =>
        [
          {
            title: 'Versefera Studios',
            subtitle: '',
            projects:
            [
              require('../json/devils-punishment.json')
            ]
          },
          {
            title: 'Happiness Factors',
            subtitle: 'Certified High Performance Life Coach',
            projects:
            [
              require('../json/happinessfactors.json')
            ]
          },
          {
            title: 'Tynker',
            subtitle: 'Heavily Gamified Programming Courses',
            projects:
            [
              require('../json/javascript-coding.json'),
              require('../json/minecraft-game-design.json'),
              require('../json/mod-design-studio.json'),
              require('../json/code-monsters.json'),
              require('../json/stunt-pilot.json'),
              require('../json/animation-studio.json')
            ]
          }
        ]
    }
  },
  components: {
    PSection: () => import('../components/PSection.vue')
  }
})
